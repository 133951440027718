import {AnaliticsServiceInterface, IEvent} from '../analitics.service'
import {ConfigStore} from '../../../core'

declare const amplitude: any

export class AmplitudeDepo implements AnaliticsServiceInterface {
  private userId: string
  private btag: string
  private qtag: string

  constructor(config: ConfigStore) {
    amplitude.getInstance().init('3397b494f8ef46edf4dec8b3db01db21')
  }

  track(event: IEvent, userId: string, btag: string, callback: ((param: any) => void) | undefined): void {
    if (!this.userId && userId !== '') {
      amplitude.getInstance().setUserId(userId)
    }

    if (!this.btag && btag !== '') {
      const identify = new amplitude.Identify().setOnce('btag', btag)
      amplitude.getInstance().identify(identify)
      this.btag = userId
    }

    amplitude.getInstance().logEvent(event.name, {
      action: event.action,
      userTgId: userId
    })
  }
}
